// "Production" enabled environment

export const environment = {
    wpa: false,
    production: true,
    hmr: false,
    remoteServiceBaseUrl: "https://posmaerapistaging.maersoft.com.ar",
    appBaseUrl: "https://posmaerstaging.maersoft.com.ar",
    localeMappings: [
        {
            "from": "es-AR",
            "to": "es"
        },
        {
            "from": "en-US",
            "to": "en"
        }
    ]
};
